.table-size-dropdown-container{
    margin : 0px 10px;
}
.table-size-dropdown-container .react-select__dropdown-indicator{
    padding: 0px;
}
.table-size-dropdown-container .react-select__indicator-separator{
    display: none;
}
.table-size-dropdown-container .react-select__control{
    border-color: #a9a9a9 !important;
    min-height: 30px !important;
}
.table-size-dropdown-container .react-select__value-container{
    padding: 2px 5px;
}