@import url('../assets/css/skills/css/course.css');
@import url('../assets/css/skills/css/ibl-skillspro-v3.webflow.css');
@import url('../assets/css/skills/css/normalize.css');
@import url('../assets/css/skills/css/webflow.css');

iframe {
  width: 100%;
  height: 100vh;
}
.text-block-113.h-align{
  display: flex;
  align-items: center;
  gap: 10px;
}