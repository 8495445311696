.credential-icon-upload-block .loader-block{
    max-width: 50px;
}
.credential-icon-upload-block{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
}
.credential-icon-upload-block .credential-file-upload-name-block{
    display: flex;
    gap: 5px;
    align-items: center;
}
.credential-icon-upload-btn-disabled{
    cursor : not-allowed!important;
    opacity: .6;
}
.credential-icon-upload-input {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}
.credential-file-upload-name-block{
    cursor: pointer;
}
.credential-file-upload-name-block:hover{
    opacity: .7;
}