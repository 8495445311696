.reports-filter-box-date-range-container{
    position: relative;
}
.date-range-container{
    position: absolute;
    z-index: 999;
    top: 70px;
}
.reports-filter-box-date-range{

}
.date-range-container:not(.active){
    display: none!important;
}
.date-range-container-closer{
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #fff;
    position: absolute;
    right: -6px;
    top: -7px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    cursor: pointer;
    opacity: .8;
}
.date-range-container-closer:hover{
    opacity: 1;
}
.date-range-container-closer img{
    width: 10px;
}
.react-select-container{
    width: 100%;
}
/*TODO to be removed*/
.report-view-btn{
    display: none!important;
}

.reports-filter-box-search-block .react-select__value-container{
    overflow: auto!important;
    display: flex!important;
    flex-direction: row!important;
    flex-wrap: nowrap!important;
    width: 200px;
}
.reports-filter-box-search-block .react-select__multi-value{
    min-width: auto!important;
}